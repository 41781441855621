body {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: "Poppins", cursive;
  /* font-size: 55px !important; */
}

.theme-color {
  color: #e57228;
}

.bg-theme-color {
  background-color: #e57228;
}

.bg-theme-dark {
  background-color: #000000;
}

.mt-n12 {
  margin-top: -10em;
}

.nav-link {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  color: #ffffff;
  font-weight: 500;
  margin-right: 15px;
}

.nav-link:focus,
.nav-link:hover {
  color: #e57228;
}

.navbar-nav .active .nav-link {
  color: #e57228;
}
.mg-0 {
  padding: 0%;
  
}

.transparent-navbar {
  background-color: transparent;
  /* Adjust the transparency as needed */
}

.navbar {
  justify-content: end;
}

.nav-ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-brand {
  width: 20%;
}

.logo-wid {
  width: 35%;
}

@media only screen and (max-width: 767px) {
  .mt-n12 {
    margin-top: -2em;
  }

  .navbar-brand {
    width: 40%;
  }

  nav {
    display: flex;
    justify-content: space-between !important;
  }

  /* .navbar-brand{
        position: relative;
        left: -52%;
    } */
  .navbar-toggler {
    background-color: black !important;
  }

  .navbar-collapse {
    background: #000000;
    padding: 15px 0;
  }

  .nav-pills .nav-link {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .nav-pills .men-im {
    height: auto !important;
    border-radius: 0px !important;
    margin-top: 0px !important;
    width: 100% !important;
    padding-top: 0px !important;
  }

  .itm-na {
    min-height: auto !important;
    padding-top: 8px;
    padding-bottom: 15px;
  }
}

.bg-poss {
  width: 90%;
  display: block;
  margin: 0% auto;
  padding: 15px;
  font-size: 15px;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 30px;
  text-align: center !important;
}

.fixed-top {
  transition: top 0.5s ease-in-out;
}

/* Default styles for all devices */
.carousel-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 53%;
  text-align: center;
}

.carousel-content h3 {
  color: #e57228;
  text-align: center;
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  margin-bottom: 25px;
}

/* Media Query for screens smaller than 576px (Mobile) */
@media only screen and (max-width: 575.98px) {
  .carousel-content {
    top: 55%;
    /* Adjust the top position */
  }

  .carousel-content h3 {
    font-size: 52px;
    /* Adjust the font size */
    line-height: 40px;
    /* Adjust the line height */
    margin-bottom: 15px;
    /* Adjust the margin */
  }
}

/* Media Query for screens between 576px and 991px (Tablet) */
@media only screen and (min-width: 576px) and (max-width: 991.98px) {
  .carousel-content {
    top: 55%;
    /* Adjust the top position */
  }

  .carousel-content h3 {
    font-size: 68px;
    /* Adjust the font size */
    line-height: 50px;
    /* Adjust the line height */
    margin-bottom: 20px;
    /* Adjust the margin */
  }
}

/* Media Query for screens between 992px and 1199px (Laptop) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-content {
    top: 50%;
    /* Adjust the top position */
  }

  .carousel-content h3 {
    font-size: 64px;
    /* Adjust the font size */
    line-height: 60px;
    /* Adjust the line height */
    margin-bottom: 20px;
    /* Adjust the margin */
  }
}

/* Media Query for screens 1200px and above (Desktop) */
@media only screen and (min-width: 1200px) {
  .carousel-content {
    top: 53%;
    /* Reset to default top position */
  }

  .carousel-content h3 {
    font-size: 60px;
    /* Reset to default font size */
    line-height: 70px;
    /* Reset to default line height */
    margin-bottom: 25px;
    /* Reset to default margin */
  }
}

.button {
  font-family: "Poppins", sans-serif;
  background-color: #e57228;
  color: #ffffff;
  padding: 10px 32px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  border: none;
}

.about-box {
  border-radius: 10px;
  padding: 40px 20px;
}

.about-box h4 {
  font-family: Poppins;
  font-size: 54px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
}

.why-bryani {
  color: #e4dec1;
  background-color: #000;
  background-image: url("../images/bg-leaf.png");
  background-size: 15%;
  background-blend-mode: hard-light;
}

.introducing {
  font-size: 60px !important;
  /* color: #e57228; */
}

.shipping-header {
  font-size: 25px !important;
}

.comn-fnt {
  font-size: 15px;

  letter-spacing: 1px;
  word-spacing: 2px;
}

.why-bryani h2 {
  font-size: 60px;
  color: #e57228;
}

.why-bryani p {
  font-size: 15px;
  text-align: justify;
  color: white;
  text-align-last: center;
  letter-spacing: 1px;
  word-spacing: 2px;
}

.bg22 {
  background-image: url("../images/bg-22.png");
}

@media only screen and (max-width: 768px) {
  .bg-2 {
    /* background-image: url("../images/about-bg-mobile.webp"); */
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
  }

  .bg-3 {
    /*background-image: url('../images/bg-3.png');*/
    background-color: #000000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* height: 222vh; */
  }

  .bg-3 h2 {
    color: #ffffff;
  }

  .bg-3 img {
    display: block;
    margin: 0 auto;
  }

  .bg-3 h3 {
    text-align: center;
  }

  .bg-4 {
    background-image: url("../images/tUSPbackground.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000;
  }

  .about-bg {
    background-image: url("../images/about-bg.png");
    background-size: cover;
    background-position: center;
  }

  .mid-logo {
    width: 75% !important;
  }

  .bg-poss {
    width: 95%;
    display: block;
    margin: 0% auto;
    padding: 15px;
    font-size: 15px;
    letter-spacing: 0px;
    word-spacing: 2px;
    line-height: 25px;
    text-align: justify !important;
  }

  .img-fluid {
    max-width: 80%;
    height: 287px;
  }

  .com-col {
    color: #e57228 !important;
  }

  .pl-5 {
    padding-left: 0%;
  }

  .bg-pos {
    /* bottom: -35%;
        left: 0%; */
    width: 100%;
    color: #fff;
  }

  .about-but-pos h3 {
    font-family: Poppins;
    font-size: 44px;
    line-height: 50px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
  }

  /* 
    .about-but-pos {
        position: absolute;
        bottom: 3%;
        left: 11%;
        text-align: center;
    } */

  .breadcum-bg {
    background-image: url("../images/breadcum-bg.png");
    background-position: center;
    background-size: cover;
    height: 60vh;
  }

  .menu-box-bg {
    /* background-image: url("../images/menu-box-bg.png"); */
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    /* padding: 25px; */
    background-repeat: no-repeat;
  }

  .menu-box-bg .nav-item {
    padding: 0 5px;
    width: 49%;
    display: flex;
    margin-bottom: 15px;
  }

  .quick-links li {
    padding-left: 10px;
  }
}

.bgg-3 {
  /* background-image: url('../images/border-img.png'); */
  /* background-position: center;
  background-color: #000;
  background-repeat: no-repeat;
  background-size:contain;
  height: 160vh;
  width: 100%; */
  background-color: #000;
  padding: 2%;
}

.menu-nav {
  display: flex;
  justify-content: center;
  padding: 0% !important;
}

.nav-item button {
  width: 90%;
  display: block;
}

.bg-3 {
  border: 2.5px solid #d4d3bf;
}

.mid-logo {
  width: 25%;
}

@media only screen and (min-width: 768px) {
  .bg-2 {
    background-image: url("../images/bg-2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-3 {
    /* background-image: url('../images/bg-3.png'); */

    /*height: 119vh;*/
  }

  .bg-4 {
    position: relative;
    background-image: url("../images/tUSPbackground.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000;
  }

  .bg-4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    /* Adjust opacity here */
    z-index: 1;
    pointer-events: none;
  }

  .about-bg {
    background-image: url("../images/about-bg.png");
    background-size: cover;
    background-position: center;
  }

  .abt-cont {
    font-size: 15px;
    text-align: justify;
    padding: 3% 0%;
  }

  /* .about-inner-bg {
        background-image: url('../images/about-inner-bg.png');
        background-size: cover;
        background-position: bottom;
        height: 186vh;
    } */

  .pl-5 {
    padding-left: 14%;
  }

  .bg-pos {
    bottom: 0;
    left: 13%;
    width: 90%;
  }

  .about-but-pos h3 {
    font-family: Poppins;
    font-size: 84px;
    font-weight: 400;
    line-height: 69px;
    letter-spacing: 0em;
    text-align: center;
  }

  .about-but-pos {
    position: absolute;
    bottom: 3%;
    left: 33%;
    text-align: center;
  }

  .breadcum-bg {
    background-image: url("../images/breadcum-bg.png");
    background-position: center;
    background-size: cover;
    height: 60vh;
  }

  .menu-box-bg {
    /* background-image: url("../images/menu-box-bg.png"); */
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    /* padding: 60px; */
    background-repeat: no-repeat;
  }

  .menu-box-bg .nav-item {
    padding: 1em;
    width: 33%;
    display: flex;
    margin-bottom: 15px;
  }

  .men-p1 {
    height: 50px;
  }

  .men-p2 {
    height: 110px;
  }

  .quick-links li {
    padding-left: 40px;
  }
}

.bg-3 h3 {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 400;
  line-height: 51px;
}

.bg-3 .theme-font {
  font-family: "Poppins", cursive;
  font-size: 45px !important;
  color: #ffffff;
}

h2 {
  font-size: 84px;
  font-weight: 400;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: center;
}

.blog-box {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.blog-box h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}

.blog-box p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.readmore-button {
  border: 1px solid #000;
  border-radius: 5px;
  width: 32%;
  padding: 5px 15px;
  text-decoration: none;
  color: #000;
  text-align: center;
}

.ups-icon {
  text-align: center;
  padding: 12px;
  border-radius: 10px;
  height: 100% !important;
}

.ups-icon img {
  margin-bottom: 20px;
}

footer {
  background-color: #000000;
}

footer h5 {
  font-family: Poppins;
  font-size: 50px;
  color: #000000;
}

.bg-orange {
  background-color: #e57228;
}

.quick-links {
  list-style: none;
}

.social {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0 32%;
}

.social li {
  padding: 0 10px;
}

.social i {
  color: white;
}

.footer-button {
  font-family: "Poppins", sans-serif;
  background-color: #f4f4f4;
  color: #000000;
  padding: 10px 32px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  border: none;
}

.nav-pills .nav-link.active {
  background-color: #e57228;
}

.nav-pills .nav-link {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  box-shadow: 0px 0px 10px 0px lightgrey;
  border: 1px solid #e57228;
  color: #e57228;
}
.nav-pills .nav-link:hover{
  background-color: #e57228;
}

.menu-box {
  margin-bottom: 20px;
  min-height: 250px;
  padding: 13px;
}

.menu-box:hover {
  border: 1px solid #9fa68c;
  padding: 12px !important;
}

.menu-box h3 {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 800;
  /* line-height: 39px; */
  letter-spacing: 0em;
  text-align: center;
  /* width: 70%; */
}

.menu-box p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #f5f5f5;
}

.menu-box-img {
  margin-bottom: 10px;
    min-height: 259px !important;
  object-fit: cover;
}

.price-button {
  /* border: 1px solid #ffffff; */
  color: #ffffff;
  border-radius: 5px;
  width: 28%;
  padding: 5px 8px;
  text-decoration: none;
  text-align: center;
}

.menu-content h3 {
  font-family: Poppins;
  font-size: 60px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: center;
}

.about-box-bg {
  /* background-image: url('../images/about-bg-new.png'); */
  background-position: center;
  background-color: #0000;
  background-size: cover;
  border-radius: 10px;
  height: 95vh;
  padding: 60px;
  background-repeat: no-repeat;
}

.bg-full-dark {
  background: #111111;
}

.bg-full-dark-1 {
  background-color: #000;
}

.menu-content h3 span {
  font-size: 50px;
}

.blog-title {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -2px;
  text-align: center;
}

/* thilak changes */

/* cor changes */
@media only screen and (min-width: 760px) {
  .cor-item {
    background-image: url("../images/1.png");
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 760px) {
  .cor-item {
    background-image: url("../images/2.png");
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.font-siz {
  font-size: 24px;
  font-family: none;
}

.cont-bg {
  background-color: #f7f4f4;
}

#footer-form input {
  background-color: white;
  border-radius: 10px !important;
}

#footer-form textarea {
  background-color: white;
  border-radius: 10px !important;
}

@media only screen and (max-width: 860px) {
  .bg-3 .theme-font {
    font-size: 36px !important;
  }

  .pos-relative {
    position: relative;
    /* background-image: url('../images/mysuru-style-bg-mobile.webp'); */
  }

  .about-box h4 {
    font-size: 44px;
    text-align: center;
  }

  .h4mob {
    font-size: 44px;
    text-align: center;
    font-family: "Poppins", cursive;
  }

  .pos-absolute {
    position: relative;
  }

  .just {
    text-align: justify;
  }

  /* .byr-bg-mob{
        background-image: url('../images/mysuru-style-bg-mobile.webp');
    } */

  .about-inner-bg {
    background-image: url("../images/tbg-1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    background-position: center;
  }

  .about-box p {
    text-align: justify;
  }

  h2 {
    font-size: 54px;
    line-height: 60px;
  }

  .bg-3 h3 {
    font-size: 28px;
    line-height: 35px;
  }

  .ups-icon img {
    padding-bottom: 5px;
  }

  .ups-icon p {
    font-size: 16px;
    font-weight: 600;
  }

  /* footer */

  .copy-rights {
    font-size: 16px;
  }

  .quick-links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .quick-links li {
    font-size: 16px;
    font-weight: 600;
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {

  /* Adjust the layout of the ul and li elements for smaller screens */
  .quick-links {
    /* flex-direction: column;
    align-items: flex-start; */
    margin: 10px;
  }

  /* Center the copyright text */
  .copy-rights {
    text-align: center;
    margin-top: 20px;
  }

  .logo-wid {
    width: 50% !important;
  }

  /* Additional styles for smaller screens */
  /* Add padding or margins as needed */
}

.logo-wid {
  width: 32%;
}

.menu-box {
  margin-bottom: 0px;
  min-height: 250px;
}

.menu-content h3 span {
  font-size: 36px;
  line-height: 38px !important;
}

.menu-content h3 {
  font-size: 45px;
  line-height: 46px;
}

.fade-blog {
  position: relative;
  height: 4.8em;
  /* exactly two lines */
}

.item-blog {
  width: 300px;
  margin: 10px;
  overflow: hidden;
  line-height: 25px !important;
}

/* Quantity Control */
.quantity-col {
  /* display: flex; */
  align-items: center !important;
  justify-content: center !important;
  /* background-color: #ffffff !important; */
  height: 25px !important;
  width: 20%;
  border-color: transparent !important;
}

.quantity-btn {
  background-color: #fff;
  color: #000000;
  /* padding: 10px 20px; */
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0px 10px 0px 10px;
  margin: 0px 10px 0px 10px;
}

.quantity-btn:hover {
  background-color: #f56a00 !important;
  color: #fff !important;
}

.product-amount {
  width: 18%;
}

/* Cart List */
.cart-list {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f5f5f5 !important;
}

.cart-list h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
}

.cart-list ul {
  list-style-type: none;
  padding: 0;
}

.cart-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total-price {
  margin-top: 10px;
  font-weight: bold;
}

.background-bt {
  background-color: #f5f5f5 !important;
}

.cart-totals-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-totals-item-btn {
  display: flex;
  justify-content: center !important;
  margin-bottom: 10px;
}

.cart-totals-item.total {
  font-weight: bold;
}

.cart-totals-item span {
  color: #555;
}

.cart-totals-item span:last-child {
  color: #333;
}

/* Shipping Address */
.shipping-address {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}

.shipping-address h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
}

.shipping-address label {
  display: block;
  margin-bottom: 10px;
}

.shipping-address input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 30px;
}

/* Payment Form */
.payment-form {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}

.payment-form h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
}

.payment-form label {
  display: block;
  margin-bottom: 10px;
}

.payment-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Cart Totals */
.cart-totals {
  /* margin-top: 20px; */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  height: auto;
  background-color: #f5f5f5;
}

.cart-totals h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #333;
}

.cart-totals-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-totals-item-btn {
  display: flex;
  justify-content: center !important;
  margin-bottom: 10px;
}

.cart-totals-item.total {
  font-weight: bold;
}

.cart-totals-item span {
  color: #555;
}

.cart-totals-item span:last-child {
  color: #333;
}

.ant-steps-item-title:after {
  background-color: #221a42 !important;
}

.card-body {
  background-color: #f5f5f5;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.p-header {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 35px !important;
}

.p-textbody {
  margin-bottom: 5px !important;
}

.bg-cl-tr {
  background-color: rgba(255, 0, 0, 0);
  /* Red color with 50% transparency */
  color: #000 !important;
  border-color: rgba(255, 0, 0, 0);
}

.bg-input {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: 38px !important;
  background-color: #f5f5f5 !important;
}

.ft-16 {
  font-size: 14px;
  font-weight: 500 !important;
}

.rectangle {
  background-color: #f2e9d4 !important;
  border-radius: 10px;
  height: 30px;
  width: 100px;
  padding: 5px;
}

.table-th {
  font-size: 14px;
  font-weight: 500 !important;
}

.table-head {
  font-size: 16px;
  font-weight: 600 !important;
}

.price-card {
  font-size: 14px;
  font-weight: 500 !important;
}

.broder-radio-img {
  border-radius: 18px;
}
.fw-14  {
  font-size: 12px !important;
  padding-left: 5px;
  margin-top: 2%;
}
.mt-80 {
  margin-top: 11% !important;
}
.mt-60 {
  margin-top: 3% !important;
}

.product-title {
  height: 70px;
  margin-top: 4%;
  justify-content: center !important;
  align-items: center;
}

.related-font {
  font-size: 40px;
  margin-top: 4%;
}

.price-amount-big {
  font-size: 25px;
  margin-top: 1%;
}

.stickout-price {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: rgb(107, 100, 100);
}

.red-code {
  color: red !important;
}

/* SplashScreen.css */

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  /* Initial opacity */
  transition: opacity 1s ease-in-out;
  /* Add a fade-in transition effect */
}

.splash-screen.fade-in {
  opacity: 1;
  /* Make the splash screen visible */
}

.text-center {
  text-align: center;
}

.logo-container {
  perspective: 1000px;
  margin-bottom: 10rem;
  /* Adjusted to relative unit */
  margin-right: 12rem;
  /* Adjusted to relative unit */
}

/* Styling for the front image */
.logo-wid-image-font {
  width: 200px;
  height: auto;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  animation: showFront 2s linear infinite;
}

/* Styling for the end image */
.logo-wid-image-end {
  width: 200px;
  height: auto;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  animation: showEnd 2s linear infinite;
}

/* Keyframes for showing the front image and hiding the end image */
@keyframes showFront {
  0% {
    opacity: 1;
    /* Fully visible */
    transform: rotateY(0deg);
  }

  100% {
    opacity: 0;
    /* Fully invisible */
    transform: rotateY(-180deg);
  }
}

/* Keyframes for showing the end image and hiding the front image */
@keyframes showEnd {
  0% {
    opacity: 0;
    /* Fully invisible */
    transform: rotateY(180deg);
  }

  100% {
    opacity: 1;
    /* Fully visible */
    transform: rotateY(0deg);
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* or -webkit-flex */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  /* optional: set max width to prevent excessive stretching */
  max-width: 350px;
}

/* Apply the 3D effect on hover or any other event */

.font-size-blogs {
  font-size: 20px !important;
  margin: 6px 0px 0px 6px;
}

.logo-wid-location {
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: #f56a00;
  border-radius: 50%;
  margin: 0px 5px 0px 5px;
}

.location-text {
  font-size: 15px;
  font-weight: 500;
  text-align: start;
  margin-bottom: 0px;
}

.address-select {
  margin: 10px 0px 0px 30px;
  align-items: center;
}

/* thilak changes */

.cont-info {
  font-size: 20px;
  font-weight: 600;
}

.say-som {
  font-size: 16px;
}

.address-sec {
  /* background-image: url('./assets/images/cont-bg.jpeg'); */
  background-color: #000;
  color: white;
  padding: 6%;
  border-radius: 20px;
}

.flip-card-sec {
  display: flex;
  justify-content: center;
}

.rot-img1 {
  width: 100%;
  object-fit: cover;
  display: block;
  margin: 0px auto;
  height: 270px;
}

.rot-img-blog {
  width: 100%;
  object-fit: cover;
  display: block;
  margin: 0px auto;
  height: 270px;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 270px;
  perspective: 1000px;
}

.flip-card-blog {
  background-color: transparent;
  width: 100%;
  height: 270px;
  perspective: 1000px;
}

.flip-card-inner-blog {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-blog:hover .flip-card-inner-blog {
  transform: rotateY(180deg);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: transparent;
  border-radius: 20px;
  color: black;
}

.flip-card-back p {
  font-size: 15px;
}

.flip-card-back {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 0px 10px;
  color: white;
  transform: rotateY(180deg);
  font-size: 17px !important;
}

.anticon-shopping-cart {
  background-color: #e57228;
  border-radius: 5px;
  padding: 3px;
  color: white;
}

.title-lite {
  font-weight: 500 !important;
  font-size: 20px !important;
  width: 100%;
  margin-top: 3%;
}

.blogspace {
  max-height: calc(2 * 1.5em);
  /* 2 lines of 1.5em each */
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.navigation-tabs-relat-byr {}

.nv-lnk {
  color: #000;
}

.nv-lnk:active {
  color: white !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nv-lnk.active {
  color: white !important;
  background-color: #e57228;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.google-button {
  background-color: #b1adad;
  border-radius: 5px;
  align-items: center;
}

.categoryCard_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-swip .categoryCard {
  border: none !important;
}

.categoryCard {
  width: 13rem !important;
  /* height: 13rem; */
  margin-top: 5%;
  border-radius: 8px !important;
  box-shadow: 0 2px 21px 0 hsla(0, 0%, 62%, 0.26);
  /* padding: 2rem 0 2rem; */
  cursor: pointer;
  position: relative;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  /* Set display to flex */
  display: flex !important;
  align-items: center;
  color: #000 !important;

  /* Initially, arrange children in rows */
  flex-direction: column !important;
  border: 0px solid #e57228 !important;
  background-color: antiquewhite;
  box-shadow: none;
  
}
.categoryCard img:hover {
  box-shadow: 0 0 4px 4px rgba(229, 114, 40, 0.8) !important;
  /* Add a more pronounced box shadow effect on hover */
  border: 1px solid rgba(229, 114, 40, 0.8) !important;
  /* Set initial border color to transparent */
 
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .categoryCard {
    width: 120px !important;
    height: 145px;
    padding: 0.4rem 0 0.7rem !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 21px 0 hsla(0, 0%, 62%, 0.26);
    cursor: pointer;
    position: relative;
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
    /* Set display to flex */
    display: flex !important;
    align-items: center;
    line-height: 12px !important;
    background-color: transparent !important;

    /* Initially, arrange children in rows */
    flex-direction: column !important;
    box-shadow: none;
    border: none;
  }
  .categoryCard:hover{
    border: none !important;
    box-shadow: none !important;
  }
  
  .categoryCard img:hover {
    box-shadow: 0 0 2px 2px rgba(229, 114, 40, 0.8) !important;
    /* Add a more pronounced box shadow effect on hover */
    border: 1px solid rgba(229, 114, 40, 0.8) !important;
  }
  .categoryCard p {
    width: 90%;
    /* margin-top: 4%; */
  }

  .card-swip .categoryCard {
    border: none;
    display: block;
    margin: auto;
  }

  .image-card {
    width: 90%;
    height: 110px !important;
  }

  .cart-totals {
    width: 100% !important;
  }

  .itm-na {
    font-size: 10px !important;
  }
}

.add-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: #e57228;
  color: #ffffff;
  padding: 0.6rem;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  width: 30%;
  /* margin-bottom: 5%; */
  border-width: 0;
  height: 32px;
  box-shadow: 0 2px 21px 0 hsla(0, 0%, 62%, 0.26) !important;
  border: 1px solid #9fa68c !important;
}

.zindex-card {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
}

/* .list-style {
  list-style-position: inside;
} */

/* Address.css */

@media (max-width: 600px) {

  /* For small screens */
  .ant-table {
    font-size: 12px;
    /* Adjust font size */
  }

  /* You can add more responsive styling here */
}

/* Target the container of the carousel indicators */
.carousel .carousel-indicators {
  display: flex;
  /* Enable flexbox */
  justify-content: flex-end;
  /* Align the indicators to the right */
  margin-bottom: 30px;
  /* Add margin at the bottom */
}

/* Style for the individual buttons */
.carousel .carousel-indicators button {
  background-color: #fff;
  /* Background color */
  border: none;
  /* No border */
  border-radius: 50%;
  /* Make buttons circular */
  width: 10px;
  /* Button width */
  height: 10px;
  /* Button height */
}

.image-hover {
  border: 1px solid transparent;
  /* Set initial border color to transparent */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition effect for border color and box shadow */
}

.image-hover:hover {
  box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.8);
  /* Add a more pronounced box shadow effect on hover */
  border: 1px solid rgba(255, 255, 255, 0.8);
  /* Set initial border color to transparent */
}

.image-hovers {
  /* box-shadow: 0 0 6px 6px rgba(229, 114, 40, 0.8) !important;
  border: 1px solid rgba(229, 114, 40, 0.8) !important; */
  background-color: #f26735 !important;
  backdrop-filter: blur(25px);
  padding: 0%;
  border-radius: 10px;
  
}

.veg_nonveg_icon {
  width: 15px;
  height: 15px;
  text-align: start;
}

.menu-text {
  font-family: "Poppins", cursive;
  font-style: normal;
}

.vg-nv-dot {
  width: 22px;
  height: 22px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}

.men-im {
  height: 120px !important;
}

.itm-na {
  min-height: 70px;
  padding-top: 10px;
  line-height: 20px;
  display: block;
  margin: auto;
}

.vg-nv-inerdot {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
}

.mob-blog-img {
  height: 30vh;
  width: 100% !important;
  object-fit: cover;
}

.strik-price {
  text-decoration: line-through;
  color: #e57228;
  font-size: 16px;
}

.vie-lse-mor-btn {
  border-radius: 30px;
  border: 1px solid #9fa68c !important;
  /* padding: 10px 30px 10px 30px !important; */
}

.veg_nonveg_icon {
  width: 15px !important;
  height: 15px !important;
  text-align: start;
}

.line-space-with {
  /* line-height: 8px !important; */
  text-align: justify !important;
}

/*  */

.food-rel {
  position: relative;
}

/* .food-rel:hover{
  box-shadow: 0 0 4px 4px rgba(229, 114, 40, 0.8) !important;
  border: 1px solid rgba(229, 114, 40, 0.8) !important;
} */

.font-sz {
  font-size: 12px !important;
}

.food-abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background 0.5s ease, color 0.5s ease;
  color: transparent;
  display: flex;
  align-items: flex-end;
  /* Start text from the bottom */
  justify-content: center;
  overflow: hidden;
  /* Hide overflow during animation */
}

.food-abs:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.1), black);
  color: white;
}

.food-abs:hover .text-to-animate {
  animation: bottomToTopText 0.5s ease-in-out forwards;
}

@keyframes bottomToTopText {
  from {
    transform: translateY(100%);
    /* Start from bottom */
    opacity: 0.5;
  }

  to {
    transform: translateY(0);
    /* End at original position */
    opacity: 1;
  }
}

#wallet .swiper-wrapper {
  min-height: 350px;
}

#lit .swiper-wrapper {
  min-height: 350px;
}

.font-sw {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: smaller;
}
.font-sw1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  /* font-size: smaller; */
}
.font-saz {
  font-size: 0.7em;
}
.pos-rel {
  position: relative;
}
.pos-ab {
  position: absolute;
  top: 80%;
  right: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: #e57228;
  color: #ffffff;
  padding: 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  width: 63%;
  /* margin-bottom: 5%; */
  border-width: 0;
  height: 32px;
  box-shadow: 0 2px 21px 0 hsla(0, 0%, 62%, 0.26) !important;
  border: 1px solid #9fa68c !important;
}
.menu-box-img1 {
  margin-bottom: 10px;
  min-height: 127px !important;
  object-fit: cover;
}
  
.ant-modal-content {
  /* background-image: url('../images/bg-kebab.webp'); */
  /* background-repeat: no-repeat;*/
  background-size: cover;
  /* border: 3px solid #ff4d4f !important;  */
  /* background-color: #f26735 !important; */
}

.keb-card {
  background-color: antiquewhite;
  backdrop-filter: blur(25px);
  padding: 2%;
  border-radius: 10px;
}

.keb-card:hover {
  background-color: #f26735 !important;
  backdrop-filter: blur(25px);
  padding: 2%;
  border-radius: 10px;
}


.ant-modal-close-x {
  width: 30px;
  height: 30px;
  border: 2px solid #ff4d4f !important;
  background-color: white;
  border-radius: 50%;
}

.prod-nam-pri {
  
  padding: 0px 3%;
}

.prod-nam-pri2{
  display: flex;
  align-items: end;
  justify-content: space-between !important;
}

.prod-pric {
  background-color: white;
  color: #ff4d4f !important;
  border: 1px solid #ff4d4f !important;
  padding: 0% 4%;
  border-radius: 5px;
}

@media only screen and (max-width:760px) {
  .prod-nam-pri {
    font-size: 12px;
  }
  
}
.btn-vna{
  margin: 0px 5px;
  border-radius: 15px;
  padding: 2px 13px;
  background-color: white;
  text-align: center;
}
.anim {
  background: linear-gradient(45deg, black, #e57228, black);
  background-size: 200% 200%;
  color: white;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  animation: shine 3s ease-in-out infinite;
  transition: transform 0.3s ease;
}

@keyframes shine {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.anim:hover {
  transform: scale(1.05);
}

/* Zoom effect for the text inside the paragraph */
.zoom {
  display: inline-block;
  animation: zoomInOut 3s ease-in-out infinite;
}

@keyframes zoomInOut {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}
.pos-absl {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  font-size: xxx-large;
}
.pos-absl2{
  position: absolute;
  top: 18%;
  right: 37%;
  font-size: xxx-large;
}
.pos-absl1{
  position: absolute;
  top: 16%;
  right: 41%;
  font-size: xxx-large;
}
.pos-abr{
  position: absolute;
  top: 30%;
  left: 50%;
transform: translateX(-50%); /* Center horizontally */
  font-size: 30px;
}

.center-image {
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height */
  text-align: center; /* Center text (if any) */
  padding-top: 10px; /* Space above the content */
  position: absolute; /* Absolute positioning relative to the nearest positioned ancestor */
  bottom: 10px; /* Distance from the bottom (adjust as needed) */
  right: 10px; /* Distance from the right (adjust as needed) */
  z-index: 999; /* Ensure it appears above other content */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}


.burg-menimg{
  width: 25px;
  fill: red;
}
.mt-70 {
  margin-top: 80px !important;
}
.font-hp {
  font-size: 46px !important;
  font-weight: 500;
}

@media (max-width: 768px) {
  .font-hp {
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 40px !important;
    
  }
  /* .video-mob {
    max-height: 100vh !important;
    object-fit: cover !important;
  } */
  #bannerVideo {
    max-height: 100vh !important;
    object-fit: cover !important;
  }
}

.bg-blacks {
  background-color: #000 !important;
}